<!--三项工程政策侧边栏-->
<template>
  <div class="notice">
    <div class="notice-title">
      <span class="fl">特色农庄</span>
      <span class="fr"
        ><router-link to="/sharehouselist"
          >更多<i class="el-icon-arrow-right"></i></router-link
      ></span>
    </div>
    <div class="notice-list">
      <div class="notice-item" v-for="item in tudiPageData" :key="item.id"
      @click="$router.push(`/sharehousedetail?id=${item.id}`)"
      >
        <el-image
          style="width: 100%; height: 100%"
          :src="item.photoUrls && item.photoUrls.split(',')[0]"
          fit="cover"
        ></el-image>
        <div class="name ellipsis">{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { GetSharedFarmPage } from "@/api/home.js";

export default {
    data() {
        return {
            pageData: {},
            tudiInfoparams: {
                currentPage: 1,
                filter: {
                },
                pageSize: 10,
            },
            nongyeInfoparams: {
                currentPage: 1,
                filter: {
                infoType: "249253465337925",
                secondInfoType: "农业政策",
                },
                pageSize: 5,
            },
            butieInfoparams: {
                currentPage: 1,
                filter: {
                infoType: "249253465337925",
                secondInfoType: "补贴政策",
                },
                pageSize: 5,
            },
            tudiPageData: {},
            nongyePageData: {},
            butiePageData: {},
        };
    },
    methods: {
        gettudiInfoList() {
          GetSharedFarmPage(this.tudiInfoparams).then((res) => {
                this.tudiPageData = res.data.data.list;
            });
        },
        getnongyeInfoList() {
            GetInformationPage(this.nongyeInfoparams).then((res) => {
                this.nongyePageData = res.data.data.list;
            });
        },
        getbutieInfoList() {
            GetInformationPage(this.butieInfoparams).then((res) => {
                this.butiePageData = res.data.data.list;
            });
        },
    },
    mounted() {
        this.gettudiInfoList();
        // this.getnongyeInfoList();
        // this.getbutieInfoList();
    }
}
</script>

<style scoped>
.notice-item{
  height: 150px;
  margin-bottom: 15px;
  position: relative;
  cursor: pointer;
}
.name{
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  color: #fff;
  text-align: center;
  font-size: 14px;
  line-height: 32px;
  background-image: linear-gradient(to top,rgba(0, 0, 0,.9) 0%,rgba(0, 0, 0,.7) 40%, transparent);

}
.notice >>> .el-carousel__button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.notice-item >>> .el-carousel__indicators--horizontal {
  bottom: 18px;
  left: auto;
  right: 10px;
  transform: translateX(0);
}
.pagination {
  text-align: center;
}
.notice-text {
  height: 28px;
  line-height: 28px;
  font-size: 16px;
  font-weight: 600;
  position: absolute;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
}
.notice-info {
  position: relative;
  height: 150px;
}
.notice-pic {
  width: 100%;
  height: 150px;
  overflow: hidden;
}
.notice-name {
  height: 30px;
  line-height: 30px;
  font-size: 16px;
  font-weight: 700;
}
.notice-list {
  border: 1px solid #ccc;
  padding: 10px;
}
.notice-title a {
  color: #fff;
}
.notice-title {
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  font-weight: 600;
  background: #de0049;
  color: #fff;
  padding: 0 10px;
}
.notice {
  /* width: 370px; */
}
</style>
